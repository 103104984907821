import React from "react";
import styled from "styled-components";
import Main from "../components/main/Main";
import BrainsterLogoSvg from "../components/brainsterLogo/BrainsterLogoSvg";

// background Homepage
const HomeDiv = styled.div`
  background: linear-gradient(90deg, #3052fb 29%, #1c0de6 100%);
  background-size: cover;
  min-height: 100%;
  height: 100vh;
  width: 100%;
`;
const HomeMainWrapper = styled.div`
  height: 100%;
  width: 90%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  position: relative;
  @media only screen and (max-width: 1160px) {
    width: 95%;
  }
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    max-width: 500px;
  }
  @media only screen and (max-width: 600px) {
    width: 90%;
  }
  @media only screen and (max-width: 900px) and (max-height: 500px) {
    flex-direction: row;
    width: 90%;
    max-width: 700px;
  }
`;
const LogoWrapper = styled.div`
  position: absolute;
  left: 0%;
  right: 89.58%;
  top: 25.78%;
  bottom: 68.67%;
  width: 55px;
  height: 55px;
  @media only screen and (max-width: 900px) {
    top: 4.78%;
  }
  @media only screen and (max-width: 490px) {
    width: 40px;
    height: 40px;
  }
  @media only screen and (max-width: 900px) and (max-height: 580px) {
    width: 40px;
    height: 40px;
  }
`;

// deprecated for the time being
// const LogoBottom = styled.p`
//   font-size: 16px;
//   font-weight: 500;
//   position: absolute;
//   left: 0%;
//   bottom: 12.67%;
//   color: #0808c4;
//   @media only screen and (max-width: 900px) {
//     bottom: 3.67%;
//     font-size: 13px;
//   }
//   @media only screen and (max-width: 900px) and (max-height: 580px) {
//     font-size: 11px;
//     bottom: 5.67%;
//   }
// `;

const Home = () => {
  return (
    <>
      <HomeDiv>
        <HomeMainWrapper>
          <LogoWrapper>
            <BrainsterLogoSvg width={"100%"} height={"100%"} colorFill={"#0808C4"} />
          </LogoWrapper>
          {/* <LogoBottom>Brainster.io/vienna-design-map</LogoBottom> //deprecated for the time being */}
          <Main />
        </HomeMainWrapper>
      </HomeDiv>
    </>
  );
};

export default Home;
