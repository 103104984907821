import React from 'react'
import styled from 'styled-components';

const NotFoundDiv = styled.div`
    padding: 40px 0; 
    text-align: center;
`

const NotFound = () => {
    return (
        <NotFoundDiv>
           <h1>The page can’t be found.</h1>
           <p>It looks like nothing was found at this location.</p>
        </NotFoundDiv>
    )
}

export default NotFound
