import React from "react";
import GlobalStyle from "./styles/GlobalStyles";
import { Provider } from "./context/context";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./containers/Home";
import Footer from "./components/footer/Footer";
import ScrollTop from './components/scrollToTop/ScrollTop';
import NotFound from "./components/notFound/NotFound";

function App() {
  return (
    <Provider>
      <>
        <GlobalStyle />
        <Router basename={process.env.PUBLIC_URL}>
          <ScrollTop>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route component={NotFound} />
            </Switch>
            <Footer />
          </ScrollTop>
        </Router>
      </>
    </Provider>
  );
}

export default App;
