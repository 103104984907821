import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
     box-sizing: border-box;
     padding: 0;
     margin: 0;
     -webkit-user-select: none; /* Safari */        
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
      user-select: none; /* Standard */
  }
html {
  scroll-behavior: smooth;
}
  body {
    font-family: 'Ubuntu', sans-serif;
  }
  ::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-thumb {
    background: rgb(136, 136, 136);
}
::-webkit-scrollbar-thumb:hover {
  background: #555656;
}
::-webkit-scrollbar-track {
    background: rgb(247, 247, 247);
}
`;

export default GlobalStyle;
