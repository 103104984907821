import React from "react";

const BrainsterLogoSvg = ({ colorFill, height, width }) => {
  return (
    <svg
      style={{
        height: height,
        width: width,
        fill: colorFill,
      }}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 53.1 52.3"
      xmlSpace="preserve"
    >
      <g>
        <path
          className="st0"
          d="M31.8,34.3l-5.6,3.2c-0.6,0.3-0.7,1-0.4,1.6c0.3,0.5,1,0.7,1.6,0.4l5-2.9l5,2.9c0.2,0.1,0.4,0.2,0.6,0.2
		c0.4,0,0.8-0.2,1-0.6c0.3-0.6,0.1-1.3-0.4-1.6L33,34.3C32.6,34.1,32.2,34.1,31.8,34.3z"
        />
        <path
          className="st0"
          d="M50.4,32.8c0,0,0-7.1,0-7.3V19c0-0.4-0.2-0.8-0.6-1l-5-2.9V9.3c0-0.4-0.2-0.8-0.6-1l-5.3-3.1
		C38.8,5.1,38.7,5,38.6,5L33,1.7c-0.4-0.2-0.8-0.2-1.2,0l-5.1,3l-5.1-3c-0.4-0.2-0.8-0.2-1.2,0L14.8,5c-0.1,0.1-0.2,0.2-0.3,0.3
		L9.1,8.4C8.7,8.6,8.5,9,8.5,9.4v5.8l-5,2.9c-0.4,0.2-0.6,0.6-0.6,1c0,0,0,7.1,0,7.3v6.5c0,0.4,0.2,0.8,0.6,1l5,2.9v5.9
		c0,0.4,0.2,0.8,0.6,1l5.3,3.1c0.1,0.1,0.2,0.2,0.3,0.2l5.6,3.2c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2l5.1-3l5.1,3
		c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2l5.6-3.2c0.1-0.1,0.2-0.2,0.3-0.3l5.4-3.1c0.4-0.2,0.6-0.6,0.6-1v-5.8l5-2.9
		C50.2,33.6,50.4,33.2,50.4,32.8z M38.6,31.7c-0.5-0.3-1.3-0.1-1.6,0.4c-0.2,0.3-0.2,0.6-0.1,0.9c0.1,0.3,0.3,0.5,0.5,0.7l5,2.9v5.1
		L38,44.4l-5-2.9c-0.5-0.3-1.3-0.1-1.6,0.4c-0.2,0.3-0.2,0.6-0.1,0.9c0.1,0.3,0.3,0.5,0.5,0.7l4,2.3l-3.5,2l-5-2.9
		c-0.2-0.1-0.4-0.2-0.7-0.1c-0.2,0-0.5,0-0.7,0.1l-5,2.9l-4.5-2.6v-5.8c0-0.6-0.5-1.2-1.2-1.2c-0.6,0-1.2,0.5-1.2,1.2v4.4l-3.3-1.9
		v-5.1l4.5-2.6l4.5,2.6v5.8c0,0.6,0.5,1.2,1.2,1.2c0.6,0,1.2-0.5,1.2-1.2v-6.3l4-2.3c0.3-0.2,0.5-0.4,0.5-0.7c0.1-0.3,0-0.6-0.1-0.9
		c-0.3-0.5-1-0.7-1.6-0.4l-4.5,2.6l-4-2.3v-5.5l4.2-2.4c0.3-0.2,0.5-0.4,0.5-0.7c0.1-0.3,0-0.6-0.1-0.9c-0.3-0.5-1-0.7-1.6-0.4
		l-4.3,2.5L10.2,22c-0.5-0.3-1.3-0.1-1.6,0.4c-0.3,0.6-0.1,1.3,0.4,1.6l5,2.9v5.3l-4.4,2.5l-4.5-2.6v-4.6l3.9,2.2
		c0.2,0.1,0.4,0.2,0.6,0.2c0.4,0,0.8-0.2,1-0.6c0.3-0.6,0.1-1.3-0.4-1.6l-5-2.9v-5.1l4.4-2.5l5.1,2.9c0.2,0.1,0.4,0.2,0.6,0.2
		c0.4,0,0.8-0.2,1-0.6c0.3-0.6,0.1-1.3-0.4-1.6l-5-2.9V10l4.5-2.6l5,2.9c0.5,0.3,1.3,0.1,1.6-0.4C22,9.7,22.1,9.4,22,9.1
		c-0.1-0.3-0.3-0.5-0.5-0.7l-4-2.3l3.5-2L26,7c0.2,0.1,0.4,0.2,0.6,0.2c0,0,0.1,0,0.1,0c0.2,0,0.5,0,0.7-0.1l5-2.9l4.5,2.6v5.8
		c0,0.6,0.5,1.2,1.2,1.2c0.6,0,1.2-0.5,1.2-1.2V8l3.3,1.9v5.1L38,17.7l-4.5-2.6V9.3c0-0.6-0.5-1.2-1.2-1.2c-0.6,0-1.2,0.5-1.2,1.2
		v6.3l-4,2.3c-0.3,0.2-0.5,0.4-0.5,0.7c-0.1,0.3,0,0.6,0.1,0.9c0.2,0.4,0.6,0.6,1,0.6c0.2,0,0.4-0.1,0.6-0.2l4.5-2.6l4,2.3v5.5
		l-4.2,2.4c-0.6,0.3-0.7,1-0.4,1.6c0.2,0.4,0.6,0.6,1,0.6c0.2,0,0.4-0.1,0.6-0.2l4.3-2.5l4.9,2.8c0.2,0.1,0.4,0.2,0.6,0.2
		c0.4,0,0.8-0.2,1-0.6c0.3-0.6,0.1-1.3-0.4-1.6l-5-2.9v-5.3l4.4-2.5l4.5,2.6v4.6l-3.9-2.2c-0.5-0.3-1.3-0.1-1.6,0.4
		c-0.3,0.6-0.1,1.3,0.4,1.6l5,2.9v5.1l-4.4,2.5L38.6,31.7z"
        />
        <path
          className="st0"
          d="M29.8,24.9c0.1-0.3,0-0.6-0.1-0.9c-0.3-0.5-1-0.7-1.6-0.4l-4.6,2.7c-0.3,0.2-0.5,0.4-0.5,0.7
		c-0.1,0.3,0,0.6,0.1,0.9c0.2,0.4,0.6,0.6,1,0.6c0.2,0,0.4-0.1,0.6-0.2l4.6-2.7C29.5,25.4,29.7,25.2,29.8,24.9z"
        />
        <path
          className="st0"
          d="M27.6,13.5c0.1-0.3,0-0.6-0.1-0.9c-0.3-0.5-1-0.7-1.6-0.4l-5,2.9l-5-2.9c-0.5-0.3-1.3-0.1-1.6,0.4
		c-0.3,0.6-0.1,1.3,0.4,1.6l5.6,3.2c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2l5.6-3.2C27.3,14.1,27.5,13.8,27.6,13.5z"
        />
      </g>
    </svg>
  );
};

export default BrainsterLogoSvg;
