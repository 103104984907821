import React from "react";

const BrainsterLogoWithText = ({height, width, colorFill}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 271.1 74.4"
      style={{
        height: height,
        width: width,
        fill: colorFill,
      }}
    >
      <g>
        <g>
          <g>
            <path
              d="M44.5,50.1l-8.6,5c-0.8,0.5-1.1,1.6-0.6,2.4s1.6,1.1,2.4,0.7l7.7-4.5l7.7,4.5c0.3,0.2,0.6,0.2,0.9,0.2
 c0.6,0,1.2-0.3,1.5-0.9c0.5-0.8,0.2-1.9-0.6-2.4l-8.6-5C45.7,49.8,45,49.8,44.5,50.1z"
            ></path>
            <path
              d="M73,47.7v-9.9c0-0.2-0.1-0.4-0.1-0.6C73,37,73,36.8,73,36.6v-9.9c0-0.6-0.3-1.2-0.9-1.5l-7.7-4.5v-9
 c0-0.6-0.3-1.2-0.9-1.5l-8.2-4.7c-0.1-0.1-0.2-0.2-0.4-0.3l-8.6-5c-0.5-0.3-1.2-0.3-1.8,0l-7.9,4.6l-7.9-4.6
 c-0.5-0.3-1.2-0.3-1.8,0l-8.6,5c-0.2,0.1-0.4,0.3-0.5,0.4l-8.3,4.8c-0.5,0.3-0.9,0.9-0.9,1.5v8.8l-7.7,4.5
 C0.4,25.5,0,26.1,0,26.7v9.9c0,0.2,0.1,0.4,0.1,0.6C0,37.4,0,37.6,0,37.8v9.9c0,0.6,0.3,1.2,0.9,1.5l7.7,4.5v9
 c0,0.6,0.3,1.2,0.9,1.5l8.2,4.7c0.1,0.1,0.2,0.2,0.4,0.3l8.6,5c0.3,0.2,0.6,0.2,0.9,0.2s0.6-0.1,0.9-0.2l7.9-4.6l7.9,4.6
 c0.3,0.2,0.6,0.2,0.9,0.2s0.6-0.1,0.9-0.2l8.6-5c0.2-0.1,0.4-0.3,0.5-0.5l8.3-4.8c0.5-0.3,0.9-0.9,0.9-1.5v-8.8l7.7-4.5
 C72.7,49,73,48.4,73,47.7z M54.9,46.1c-0.8-0.5-2-0.2-2.4,0.7c-0.2,0.4-0.3,0.9-0.2,1.3c0.1,0.5,0.4,0.8,0.8,1.1l7.7,4.5v7.9
 L54,65.5L46.3,61c-0.8-0.5-2-0.2-2.4,0.6c-0.2,0.4-0.3,0.9-0.2,1.3c0.1,0.5,0.4,0.8,0.8,1.1l6.1,3.5l-5.4,3.1l-7.7-4.5
 c-0.3-0.2-0.7-0.2-1-0.2c-0.4,0-0.7,0-1,0.2l-7.7,4.5L21,66.7v-8.9c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8v6.8l-5.1-2.9v-7.9
 l6.8-3.9l6.8,3.9v8.9c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8V53l6.2-3.6c0.4-0.2,0.7-0.6,0.8-1.1c0.1-0.5,0.1-0.9-0.2-1.3
 c-0.5-0.8-1.6-1.1-2.4-0.7l-6.9,4l-6.2-3.6v-8.3l6.4-3.7c0.4-0.2,0.7-0.6,0.8-1.1c0.1-0.5,0.1-0.9-0.2-1.3
 c-0.5-0.8-1.6-1.1-2.4-0.6l-6.6,3.7l-7.5-4.3c-0.8-0.5-2-0.2-2.4,0.7c-0.5,0.8-0.2,1.9,0.7,2.4l7.7,4.5v8.2l-6.8,3.9l-6.9-4v-7.1
 l5.9,3.4c0.3,0.2,0.6,0.2,0.9,0.2c0.6,0,1.2-0.3,1.5-0.9c0.5-0.8,0.2-1.9-0.7-2.4l-7.7-4.5v-7.9l6.8-3.9l7.8,4.5
 c0.3,0.2,0.6,0.2,0.9,0.2c0.6,0,1.2-0.3,1.5-0.9c0.5-0.8,0.2-1.9-0.7-2.4l-7.7-4.5v-7.9l6.8-3.9l7.7,4.5c0.8,0.5,2,0.2,2.4-0.6
 c0.2-0.4,0.3-0.9,0.2-1.3c-0.1-0.5-0.4-0.8-0.8-1.1l-6.1-3.5l5.4-3.1l7.7,4.5c0.3,0.2,0.6,0.2,0.9,0.2c0.1,0,0.1,0,0.2,0
 c0.3,0,0.7,0,1-0.2l7.7-4.5L52,7.6v8.9c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8V9.7l5.1,2.9v7.9l-6.8,3.9l-6.8-3.9v-8.9
 c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8v9.7l-6.1,3.6c-0.4,0.2-0.7,0.6-0.8,1.1c-0.1,0.5-0.1,0.9,0.2,1.3c0.3,0.5,0.9,0.9,1.5,0.9
 c0.3,0,0.6-0.1,0.9-0.2l6.9-4l6.2,3.6V36l-6.4,3.7c-0.8,0.5-1.1,1.6-0.6,2.4c0.3,0.5,0.9,0.9,1.5,0.9c0.3,0,0.6-0.1,0.9-0.2
 l6.6-3.8l7.5,4.3c0.3,0.2,0.6,0.2,0.9,0.2c0.6,0,1.2-0.3,1.5-0.9c0.5-0.8,0.2-1.9-0.7-2.4l-7.7-4.5v-8.2l6.8-3.9l6.9,4v7.1
 l-6-3.4c-0.8-0.5-2-0.2-2.4,0.7c-0.5,0.8-0.2,1.9,0.7,2.4l7.7,4.5v7.9l-6.8,3.9L54.9,46.1z"
            ></path>
            <path
              d="M41.4,35.6c0.1-0.5,0.1-0.9-0.2-1.3c-0.5-0.8-1.6-1.1-2.4-0.7l-7.1,4.1c-0.4,0.2-0.7,0.6-0.8,1.1
 c-0.1,0.5-0.1,0.9,0.2,1.3c0.3,0.5,0.9,0.9,1.5,0.9c0.3,0,0.6-0.1,0.9-0.2l7.1-4.1C40.9,36.4,41.2,36,41.4,35.6z"
            ></path>
            <path
              d="M38,18.2c0.1-0.5,0.1-0.9-0.2-1.3c-0.5-0.8-1.6-1.1-2.4-0.6l-7.7,4.5L20,16.3c-0.8-0.5-2-0.2-2.4,0.6
 c-0.5,0.8-0.2,1.9,0.6,2.4l8.6,5c0.3,0.2,0.6,0.2,0.9,0.2s0.6-0.1,0.9-0.2l8.6-5C37.6,19,37.9,18.6,38,18.2z"
            ></path>
          </g>
        </g>
        <g>
          <path
            d="M101.1,37.6c-0.2-0.1-0.2-0.4,0-0.5c1.3-0.9,2.2-2.3,2.2-4.3c0-3.4-2.4-6-7-6H86.2c-0.4,0-0.7,0.3-0.7,0.7
 v20.3c0,0.4,0.3,0.7,0.7,0.7h11.1c4.4,0,7.1-2.5,7.1-6.1C104.2,40.1,103,38.5,101.1,37.6z M91,32.5c0-0.3,0.2-0.6,0.6-0.6h4.3
 c1.2,0,1.8,0.7,1.8,1.7c0,1-0.6,1.7-2.1,1.7h-4c-0.3,0-0.6-0.3-0.6-0.6V32.5z M96.6,43.5h-5c-0.3,0-0.6-0.3-0.6-0.6v-2.7
 c0-0.3,0.2-0.6,0.6-0.6h5c1.5,0,2.1,0.8,2.1,1.9C98.6,42.7,98.1,43.5,96.6,43.5z"
          ></path>
          <path
            d="M122.8,42.5c-0.2-0.2-0.1-0.6,0.2-0.7c2.6-1.4,3.8-4.1,3.8-6.9c0-4.3-2.6-7.9-8.1-7.9h-9.9
 c-0.4,0-0.7,0.3-0.7,0.7V48c0,0.4,0.3,0.7,0.7,0.7h4.2c0.4,0,0.7-0.3,0.7-0.7v-4.6c0-0.3,0.2-0.6,0.6-0.6h1.7
 c0.3,0,0.7,0.2,0.9,0.4l3.4,4.8c0.3,0.3,0.6,0.6,1.1,0.6h5.3c0.3,0,0.4-0.3,0.3-0.6L122.8,42.5z M118.3,37.7h-4.2
 c-0.3,0-0.6-0.2-0.6-0.6v-4.7c0-0.3,0.2-0.6,0.6-0.6h4.2c2.2,0,3.1,1.2,3.1,2.9S120.5,37.7,118.3,37.7z"
          ></path>
          <path
            d="M143.1,27.5c-0.1-0.4-0.5-0.6-0.9-0.6H138c-0.4,0-0.8,0.3-0.9,0.6l-8.3,20.4c-0.1,0.3,0.1,0.6,0.4,0.6h4.7
 c0.4,0,0.8-0.2,0.9-0.6l0.8-2c0.1-0.3,0.4-0.5,0.8-0.5h7.4c0.3,0,0.6,0.2,0.8,0.5l0.8,2c0.1,0.4,0.5,0.6,0.9,0.6h4.7
 c0.3,0,0.5-0.3,0.4-0.6L143.1,27.5z M142,40.4h-3.6c-0.3,0-0.4-0.3-0.4-0.5l2-5.3c0.1-0.2,0.3-0.2,0.4,0l2,5.3
 C142.4,40.1,142.2,40.4,142,40.4z"
          ></path>
          <path
            d="M159.6,26.9h-4.2c-0.4,0-0.7,0.3-0.7,0.7v20.3c0,0.4,0.3,0.7,0.7,0.7h4.2c0.4,0,0.7-0.3,0.7-0.7V27.6
 C160.3,27.2,160,26.9,159.6,26.9z"
          ></path>
          <path
            d="M184.2,26.9H180c-0.4,0-0.7,0.3-0.7,0.7v10.8c0,0.2-0.2,0.3-0.3,0.1l-8.9-11.1c-0.3-0.3-0.7-0.5-1.1-0.5h-3.4
 c-0.4,0-0.7,0.3-0.7,0.7v20.3c0,0.4,0.3,0.7,0.7,0.7h4.2c0.4,0,0.7-0.3,0.7-0.7V37c0-0.2,0.2-0.3,0.3-0.1l8.9,11.1
 c0.2,0.3,0.7,0.5,1.1,0.5h3.4c0.4,0,0.7-0.3,0.7-0.7V27.6C184.9,27.2,184.6,26.9,184.2,26.9z"
          ></path>
          <path
            d="M198.4,34.9c-2.6-0.9-3.6-1.3-3.6-2.2c0-0.7,0.8-1.5,2.6-1.5s2.9,0.8,3.2,1.8c0.2,0.5,0.4,0.8,0.9,0.8h3.9
 c0.4,0,0.8-0.4,0.7-0.8c-0.3-3.9-3.8-6.5-8.9-6.5c-4.9,0-8.5,2.6-8.5,6.7c0,4.5,4.5,6.1,8,7.3c2.6,0.9,3.7,1.4,3.7,2.4
 c0,0.6-0.7,1.3-2.8,1.3c-1.9,0-3.1-0.7-3.5-1.4c-0.3-0.6-0.5-0.7-1.1-0.7h-3.8c-0.4,0-0.8,0.4-0.7,0.8c0.4,4,4.3,6.1,9.2,6.1
 s8.6-2.2,8.6-6.4C206.6,37.8,201.4,36,198.4,34.9z"
          ></path>
          <path
            d="M227.5,26.9h-17.7c-0.4,0-0.7,0.3-0.7,0.7v4.1c0,0.4,0.3,0.7,0.7,0.7h5.5c0.3,0,0.6,0.3,0.6,0.6v15.1
 c0,0.4,0.3,0.7,0.7,0.7h4.2c0.4,0,0.7-0.3,0.7-0.7V32.8c0-0.3,0.2-0.6,0.6-0.6h5.5c0.4,0,0.7-0.3,0.7-0.7v-4.1
 C228.1,27.2,227.8,26.9,227.5,26.9z"
          ></path>
          <path
            d="M247.5,26.9h-15.3c-0.4,0-0.7,0.3-0.7,0.7v20.3c0,0.4,0.3,0.7,0.7,0.7h15.3c0.4,0,0.7-0.3,0.7-0.7v-4
 c0-0.4-0.3-0.7-0.7-0.7h-9.9c-0.3,0-0.6-0.3-0.6-0.6v-1.7c0-0.3,0.2-0.6,0.6-0.6h7.9c0.4,0,0.7-0.3,0.7-0.7v-3.7
 c0-0.4-0.3-0.7-0.7-0.7h-7.9c-0.3,0-0.6-0.3-0.6-0.6v-2c0-0.3,0.2-0.6,0.6-0.6h9.9c0.4,0,0.7-0.3,0.7-0.7v-4.1
 C248.2,27.2,247.9,26.9,247.5,26.9z"
          ></path>
          <path
            d="M271,48l-4-5.5c-0.2-0.2-0.1-0.6,0.2-0.7c2.6-1.4,3.8-4.1,3.8-6.9c0-4.3-2.6-7.9-8.1-7.9H253
 c-0.4,0-0.7,0.3-0.7,0.7V48c0,0.4,0.3,0.7,0.7,0.7h4.2c0.4,0,0.7-0.3,0.7-0.7v-4.6c0-0.3,0.2-0.6,0.6-0.6h1.7
 c0.3,0,0.7,0.2,0.9,0.4l3.4,4.8c0.3,0.3,0.6,0.6,1.1,0.6h5.3C271,48.6,271.2,48.2,271,48z M262.5,37.7h-4.1
 c-0.3,0-0.6-0.2-0.6-0.6v-4.7c0-0.3,0.2-0.6,0.6-0.6h4.2c2.2,0,3.1,1.2,3.1,2.9C265.6,36.4,264.8,37.7,262.5,37.7z"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default BrainsterLogoWithText;
