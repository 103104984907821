import styled from "styled-components";

export const ButtonCircle = styled.div`
  &.buttons {
    position: absolute;
    cursor: pointer;
    background: #fcfdfd;
    border-radius: 50%;
    height: 34px;
    width: 34px;
    @media only screen and (max-width: 900px) {
      height: 28px;
      width: 28px;
    }
    @media only screen and (max-width: 490px) {
      height: 22px;
      width: 22px;
    }
    @media only screen and (max-width: 400px)  {
      height: 20px;
      width: 20px;
    }
    @media only screen and (max-width: 374px) {
      height: 18px;
      width: 18px;
    }
    @media only screen and (max-width: 900px) and (max-height: 580px) {
      height: 18px;
      width: 18px;
    }
  }
  &.top {
    top: 0%;
    right: 48%;
    @media only screen and (max-width: 1270px) {
      top: -0.5% !important;
    }
    @media only screen and (max-width: 1060px) {
      top: -1% !important;
    }
    @media only screen and (max-width: 900px) {
      top: 0% !important;
    }
  }

  &.right {
    right: 0%;
    top: 22.5%;
    @media only screen and (max-width: 1270px) {
      right: -0.5% !important;
    }
    @media only screen and (max-width: 900px) {
      right: 0% !important;
    }
  }
  &.middle {
    right: 48%;
    top: 47%;
    @media only screen and (max-width: 1441px) {
      top: 46.5% !important;
    }
    @media only screen and (max-width: 1130px) {
      top: 46% !important;
    }
    @media only screen and (max-width: 900px) {
      top: 46.5% !important;
    }
  }
  &.left {
    left: 0%;
    top: 70%;
    @media only screen and (max-width: 1270px) {
      left: -0.5% !important;
    }
    @media only screen and (max-width: 1024px) {
      left: -1% !important;
    }
    @media only screen and (max-width: 900px) {
      left: 0% !important;
    }
  }
  &.bottom {
    right: 48%;
    bottom: 0%;
    @media only screen and (max-width: 1270px) {
      right: 48%;
      bottom: -0.5% !important;
    }
    @media only screen and (max-width: 1024px) {
      right: 48%;
      bottom: -1% !important;
    }
    @media only screen and (max-width: 900px) {
      bottom: 0% !important;
    }
  }
`;
export const ButtonCircleInner = styled.div`
  position: absolute;
  background: #365eff;
  border-radius: 50%;
  height: 22px;
  width: 22px;
  top: 50%;
  left: 50%;
  margin: -11px 0px 0px -11px;
  transition: 0.2s ease;
  @media only screen and (max-width: 900px) {
    height: 18px;
    width: 18px;
    margin: -9px 0px 0px -9px;
  }
  @media only screen and (max-width: 490px) {
    height: 14px;
    width: 14px;
    margin: -7px 0px 0px -7px;
  }
  @media only screen and (max-width: 400px) {
    height: 12px;
    width: 12px;
    margin: -6px 0px 0px -6px;
  }
  @media only screen and (max-width: 374px) {
    height: 10px;
    width: 10px;
    margin: -5px 0px 0px -5px;
  }
  @media only screen and (max-width: 900px) and (max-height: 580px) {
    height: 10px;
    width: 10px;
    margin: -5px 0px 0px -5px;
  }

  &.active {
    background: #e62e34;
    height: 24px;
    width: 24px;
    margin: -12px 0px 0px -12px;
    @media only screen and (max-width: 900px) {
      height: 10px;
      width: 10px;
      margin: -5px 0px 0px -5px;
      background: transparent;
    }
  }
`;
