import styled from "styled-components";

export const CategoryBtnText = styled.p`
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
  position: absolute;
  @media only screen and (max-width: 1023px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 490px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 376px) {
    font-size: 11px;
  }
  @media only screen and (max-width: 900px) and (max-height: 580px) {
    font-size: 13px;
  }
  @media only screen and (max-width: 695px) and (max-height: 580px) {
    font-size: 11px;
  }
  &.top {
    top: 1%;
    right: 59%;
    @media only screen and (max-width: 900px) {
      top: 0.5% !important;
    }
  }
  &.right {
    right: 10.5%;
    top: 23.5%;
    @media only screen and (max-width: 900px) {
      top: 23% !important;
    }
  }
  &.middle {
    top: 57%;
    left: 49%;
  }
  &.left {
    bottom: 24.5%;
    left: 9.5%;
    @media only screen and (max-width: 1160px) {
      bottom: 23% !important;
    }
    @media only screen and (max-width: 900px) {
      bottom: 24% !important;
    }
  }
  &.bottom {
    bottom: 1%;
    left: 57%;
  }
`;
