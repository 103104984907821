import React from "react";
import styled from "styled-components";
import { Avatar } from "@material-ui/core";
import logo from "../../static/images/Brainster-logo-symbol-01.svg";

const PopUpListItemImg = styled(Avatar)`
  height: 43px !important;
  width: 43px !important;
  @media only screen and (max-width: 1465px) {
    height: 40px !important;
    width: 40px !important;
  }
  @media only screen and (max-width: 1270px) {
    height: 36px !important;
    width: 36px !important;
  }
  @media only screen and (max-width: 900px) {
    height: 50px !important;
    width: 50px !important;
  }
`;
const PopUpListItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 0;
  @media only screen and (max-width: 1065px) {
    padding: 7px 0;
  }
  @media only screen and (max-width: 900px) {
    padding: 11px 0;
  }
`;
const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const PopUpListItemName = styled.p`
  margin-left: 10px;
  font-size: 11px;
  color: #005eff;
  font-weight: 500;
  @media only screen and (max-width: 900px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 490px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 900px) and (max-height: 580px) {
    font-size: 16px;
  }
`;
const PopUpListItemParagraph = styled.p`
  font-size: 9px;
  color: #000000;
  margin-left: 10px;
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 490px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 900px) and (max-height: 580px) {
    font-size: 12px;
  }
`;
const PopUpListItemSpan = styled.span`
  font-weight: bold;
`;
// const PopUpListItemLocation = styled(PopUpListItemParagraph)`
// white-space: nowrap;
//   @media only screen and (max-width: 490px) {
//     display: none;
//   }
//   @media only screen and (max-width: 900px) and (max-height: 580px) {
//     display: none;
//   }
// `;
const PopUpListItemDevider = styled.div`
  border: 1px dashed #005eff;
`;
const StyledLink = styled.a`
  text-decoration: none;
`;
const ComingSoon = styled(PopUpListItemName)`
  font-size: 14px;
  text-shadow: 2px 2px 3px rgba(255,255,255,0.1);
`;

const PopUpListItem = ({ listItemContent }) => {
  return (
    <StyledLink target="_blank" href={listItemContent.href} onClick={e => e.stopPropagation()}>
      <PopUpListItemWrapper>
        <AvatarWrapper>
          <PopUpListItemImg src={logo}></PopUpListItemImg>
          <div>
            {listItemContent.name === "Coming soon ..." ? (
              <ComingSoon>{listItemContent.name}</ComingSoon>
            ) : (
              <PopUpListItemName>{listItemContent.name}</PopUpListItemName>
            )}
            <PopUpListItemParagraph>
              <PopUpListItemSpan>
                {listItemContent.desc && listItemContent.desc}
              </PopUpListItemSpan>
            </PopUpListItemParagraph>
          </div>
        </AvatarWrapper>
        {/* <PopUpListItemLocation>
          {listItemContent.city},{" "}
          <PopUpListItemSpan>{listItemContent.country}</PopUpListItemSpan>
        </PopUpListItemLocation> */}
      </PopUpListItemWrapper>
      <PopUpListItemDevider></PopUpListItemDevider>
    </StyledLink>
  );
};

export default PopUpListItem;


