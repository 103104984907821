import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import BrainsterLogoWithText from "../brainsterLogo/BrainsterLogoWithText.js";

const FooterContainer = styled.footer`
  background: rgb(35, 36, 38);
  padding: 100px 0;
  @media only screen and (max-width: 900px) {
    padding: 50px 0;
  }
`;
const FooterInner = styled.div`
  max-width: 1440px;
  height: 100%;
  width: 90%;
  margin: 0px auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 900px) {
    max-width: 500px;
    flex-direction: column;
  }
`;
const FooterLogoDiv = styled.div`
  height: 40px;
`;

const FooterLinkDiv = styled.div`
  align-self: flex-start;
  display: ${(props) => props.flex};
  width: 100%;
  margin-right: ${(props) => props.marginR};
  @media only screen and (max-width: 900px) {
    margin-bottom: ${(props) => props.bottomMargin};
  }
`;
const FooterUl = styled.ul`
  list-style-type: none;
`;
const FooterLi = styled.li`
  margin-bottom: 12px;
  margin-right: ${(props) => props.marginR};
`;
const FooterLinkHref = styled.a`
  text-decoration: none;
  outline: none;
  color: rgb(101, 103, 108);
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
  @media only screen and (max-width: 490px) {
    font-size: 14px;
  }
`;
const FooterHeading = styled.h5`
  margin-bottom: 18px;
  color: rgb(255, 255, 255);
  font-size: 18px;
  @media only screen and (max-width: 490px) {
    font-size: 14px;
  }
`;
const SocialMediaAside = styled.aside`
  display: flex;
`;
const Footer = () => {
  return (
    <FooterContainer>
      <FooterInner>
        <FooterLinkDiv bottomMargin={"30px"}>
          <FooterLogoDiv>
            <Link to="/">
              <BrainsterLogoWithText height={"40px"} colorFill={"#FFF"} />
            </Link>
          </FooterLogoDiv>
        </FooterLinkDiv>
        <FooterLinkDiv bottomMargin={"20px"} flex={"flex"}>
          <FooterLinkDiv marginR={"10px"}>
            <FooterUl>
              <FooterHeading>Company</FooterHeading>
              <FooterLi>
                <FooterLinkHref
                  href="https://blog.brainster.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog
                </FooterLinkHref>
              </FooterLi>
              <FooterLi>
                <FooterLinkHref
                  href="https://medium.com/brainster-int/the-brainster-team-b7064c0454dd"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Our Team
                </FooterLinkHref>
              </FooterLi>
              <FooterLi>
                <FooterLinkHref
                  href="https://www.brainster.io/faq"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FAQ
                </FooterLinkHref>
              </FooterLi>
              <FooterLi>
                <FooterLinkHref
                  href="https://www.brainster.io/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy policy
                </FooterLinkHref>
              </FooterLi>
            </FooterUl>
          </FooterLinkDiv>
          <FooterLinkDiv marginR={"10px"}>
            <FooterUl>
              <FooterHeading>Contact Us</FooterHeading>
              <FooterLi>
                <FooterLinkHref href="mailto:alex@brainster.io">
                  Get In Touch
                </FooterLinkHref>
              </FooterLi>
              <FooterLi>
                <FooterLinkHref
                  href="https://www.brainster.io/apply"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Apply For A Bootcamp
                </FooterLinkHref>
              </FooterLi>
              <FooterLi>
                <FooterLinkHref
                  href="https://www.brainster.io/partnerships"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Hire Our Students
                </FooterLinkHref>
              </FooterLi>
            </FooterUl>
          </FooterLinkDiv>
        </FooterLinkDiv>
        <FooterLinkDiv flex={"flex"}>
          <FooterLinkDiv marginR={"10px"}>
            <FooterUl>
              <FooterHeading>Social Media</FooterHeading>
              <SocialMediaAside>
                <FooterLi marginR={"16px"}>
                  <FooterLinkHref
                    href="https://www.facebook.com/brainster.io"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Fb
                  </FooterLinkHref>
                </FooterLi>
                <FooterLi marginR={"16px"}>
                  <FooterLinkHref
                    href="https://www.instagram.com/brainsterco/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ig
                  </FooterLinkHref>
                </FooterLi>
                <FooterLi marginR={"16px"}>
                  <FooterLinkHref
                    href="https://twitter.com/brainsterio"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tw
                  </FooterLinkHref>
                </FooterLi>
                <FooterLi marginR={"16px"}>
                  <FooterLinkHref
                    href="https://www.linkedin.com/school/brainster-co/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Li
                  </FooterLinkHref>
                </FooterLi>
              </SocialMediaAside>
            </FooterUl>
          </FooterLinkDiv>
          <FooterLinkDiv marginR={"10px"}>
            <FooterUl>
              <FooterHeading>Press</FooterHeading>
              <FooterLi>
                <FooterLinkHref
                  href="https://www.trendingtopics.at/bei-brainster-lernst-du-ux-design-und-data-science-indem-du-an-echten-projekten-arbeitest/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Trending Topics
                </FooterLinkHref>
              </FooterLi>
            </FooterUl>
          </FooterLinkDiv>
        </FooterLinkDiv>
      </FooterInner>
    </FooterContainer>
  );
};

export default Footer;
