const Category = [
  {
    id: 1,
    categoryText: "Top UX/UI Designers",
    class: "top",
    popUpActive: false,
    items: [
      {
        name: "Sergiu Clipei",
        desc: "UX Designer",
        city: "Vienna",
        country: "Austria",
        href: "https://linkedin.com/in/sergiu-clipei"
      },
      {
        name: "Frane Potrč",
        desc: "UX/UI Designer",
        city: "Zagreb",
        country: "Croatia",
        href: "https://www.linkedin.com/in/frane-potrc/"
      },
      {
        name: "Mariana Carvalhão Marques",
        desc: "UX/UI Designer",
        city: "Lisbon",
        country: "Portugal",
        href: "https://www.linkedin.com/in/immarianamarques/"
      },
      {
        name: "Natalija Markoska",
        desc: "UX/UI Designer",
        city: "Struga",
        country: "North Macedonia",
        href: "https://www.linkedin.com/in/natalija-markoska-339a74105/"
      },
      {
        name: "Partik Inzinger",
        desc: "User Interface Designer",
        city: "Vienna",
        country: "Austria",
        href: "https://blog.brainster.io/patrik-inzinger-beginners-should-ask-for-projects-that-require-problem-solving-i-ux-ui-design/"
      },
      {
        name: "Nenad Milosevic",
        desc: "Product/Interface Designer",
        city: "Salzburg",
        country: "Austria",
        href: "https://blog.brainster.io/uxuidesign-nenad-milosevic-your-career-is-a-design-project-as-well-you-have-to-keep-updating-it/",
      },
      {
        name: "Mitch Loewenherz",
        desc: "Digital Experience Designer",
        city: "Graz",
        country: "Austria",
        href: "https://blog.brainster.io/uxuidesign-mitch-loewenherz-designing-without-research-is-like-running-an-obstacle-course-in-the-dark-without-a-flashlight/",
      },
      {
        name: "Mihai Delapeta",
        desc: "Product Designer",
        city: "Austria",
        country: "Austria",
        href: "https://blog.brainster.io/uxuidesign-mihai-delapeta-designing-products-that-have-the-users-best-interest-at-heart/",
      },
      {
        name: "Zsofia Czeman",
        desc: "UX Designer",
        city: "Salzburg",
        country: "Austria",
        href: "https://blog.brainster.io/uxuidesign-zsofia-czeman-failing-to-continuously-improve-digital-products-is-risking-relevance/",
      },
      {
        name: "Thomas Pernkopf",
        desc: "UX/UI Designer",
        city: "Salzburg",
        country: "Austria",
        href: "https://www.linkedin.com/in/thomaspernkopf/",
      },
    ],
  },

  {
    id: 2,
    categoryText: "UX/UI Design Agencies",
    popUpActive: false,
    class: "right",
    items: [{ name: "Coming soon ..." }],
  },
  {
    id: 3,
    categoryText: "Design Studios",
    popUpActive: false,
    class: "middle",
    items: [
      {
        name: "Olchinsky",
        city: "Vienna",
        country: "Austria",
        desc: "Atelier",
        href: "https://blog.brainster.io/uxuidesign-atelier-olschinsky-work-as-much-as-you-can-the-journey-is-the-award/",
      },
      {
        name: "KR8 Bureau",
        city: "Salzburg",
        country: "Austria",
        desc: "Brand Identity Agency",
        href: "https://blog.brainster.io/uxuidesign-florian-kowatz-kr8-bureau/",
      },
    ],
  },
  {
    id: 4,
    categoryText: "Art Directors",
    popUpActive: false,
    class: "left",
    items: [{ name: "Coming soon ..." }],
  },
  {
    id: 5,
    categoryText: "Graphic Designers",
    class: "bottom",
    popUpActive: false,
    items: [
      {
        name: "Jasna Salih",
        desc: "Graphic Designer",
        city: "Skopje",
        country: "North Macedonia",
        href: "https://www.linkedin.com/in/jasna-salih-a37676131/"
      },
      {
        name: "Laura Portillo",
        desc: "Graphic Designer",
        city: "Vienna",
        country: "Austria",
        href: "https://groovyroo.net/portfolio"
      },
      {
        name: "Anna Haury",
        desc: "Graphic Designer / Illustrator",
        city: "Vienna",
        country: "Austria",
        href: "https://blog.brainster.io/anna-haury-dont-be-afraid-to-get-inspired-design/"
      },
    ],
  },
];
export default Category;
