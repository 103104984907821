import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PopUpListItem from "./PopUpListItem";
import { v4 as uuidv4 } from "uuid";
import { Context } from "../../context/context";
import button from "../../static/images/Button.svg";

const PopUpWrapper = styled.div`
  width: 53%;
  height: 42%;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out 0s;
  @media only screen and (max-width: 900px) {
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    width: 100%;
    height: 100%;
  }
  &.active {
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }
  &.top {
    bottom: 76%;
    right: 59%;
  }
  &.right {
    top: 5.5%;
    right: 9.5%;
  }
  &.middle {
    top: 29%;
    right: 59%;
  }
  &.left {
    top: 52%;
    left: 9.5%;
  }
  &.bottom {
    top: 75%;
    left: 57%;
    @media only screen and (max-width: 1310px) {
      right: 59%;
      left: auto;
    }
  }
`;
const PopUpInner = styled.div`
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 10px 15px;
  z-index: 3;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px !important;
    background: transparent !important;
  }
  @media only screen and (max-width: 900px) {
    padding: 25px 15px;
  }
`;
const LessIcon = styled(ExpandLessIcon)`
  cursor: pointer;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media only screen and (max-width: 900px) {
    display: none !important;
  }
`;
const MoreIcon = styled(ExpandMoreIcon)`
  cursor: pointer;
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media only screen and (max-width: 900px) {
    display: none !important;
  }
`;
const ButtonPopUp = styled.img`
  position: absolute;
  right: 15px;
  top: 2%;
  z-index: 222;
  cursor: pointer;
  @media only screen and (max-width: 900px) {
    display: block;
    top: 4%;
  }
`;

const PopUp = ({ className, popUpListItem }) => {
  const { activeClass, setActiveClass } = useContext(Context);

  useEffect(() => {
    document
      .querySelectorAll(".popInnerScroll")
      .forEach((el) => (el.scrollTop = 0));
  }, [activeClass]);



  function scrolldown() {
    document
      .querySelectorAll(".popInnerScroll")
      .forEach((el) => (el.scrollTop -= 40));
  }
  function scrollup() {
    document
      .querySelectorAll(".popInnerScroll")
      .forEach((el) => (el.scrollTop += 40));
  }
  return (
    <PopUpWrapper className={`popUp ${className}`}>
      <PopUpInner className={`popInnerScroll`}>
        <ButtonPopUp src={button} onClick={() => setActiveClass([])} />
        <LessIcon onClick={scrolldown} />
        <MoreIcon onClick={scrollup} />
        {popUpListItem && popUpListItem.map((item) => (
          <PopUpListItem listItemContent={item} key={uuidv4()} />
        ))}
      </PopUpInner>
    </PopUpWrapper >
  );
};

export default PopUp;
