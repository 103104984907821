import React, { useContext } from "react";
import styled from "styled-components";
import img from "../../static/images/Svg.svg";
import Category from "../category/Category";
import { Context } from "../../context/context";
const MainRight = styled.div`
  flex: 1;
  position: relative;
  height: 100%;
`;
const MainLeft = styled.div`
  flex: 1;
  position: relative;
  height: 100%;
`;
const Vector = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  user-select: none;
`;
const TitleWrapper = styled.div`
  position: absolute;
  left: 0%;
  top: 45%;
  a{
    text-decoration: none;
    color: unset;
  }
  button{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0 10px 0;
    font-size: 18px;
    font-family: 'Ubuntu', sans-serif;
    background-color: #FCD232;
    border: none;
    outline: none;
    box-shadow: 2px 3px 14px 0px rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    font-weight: 500;
    color: rgb(8, 8, 196);
    margin-top: 12px;
    &:hover{
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 900px) {
    top: 30%;
  }
  @media only screen and (max-width: 490px){
    button{
      width: 80%;
      font-size: 16px;
    }
  }
`;
const TitleMap = styled.h2`
  font-size: 55px;
  font-weight: bold;
  color: #ffffff;
  @media only screen and (max-width: 1380px) {
    font-size: 46px;
  }
  @media only screen and (max-width: 1065px) {
    font-size: 42px;
  }
  @media only screen and (max-width: 490px) {
    font-size: 26px;
  }
  @media only screen and (max-width: 900px) and (max-height: 580px) {
    font-size: 26px;
  }
`;
const SubTitleMap = styled.h3`
  font-size: 25px;
  color: #ff003a;
  @media only screen and (max-width: 1380px) {
    font-size: 21px;
  }
  @media only screen and (max-width: 1065px) {
    font-size: 19px;
  }
  @media only screen and (max-width: 490px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 900px) and (max-height: 580px) {
    font-size: 12px;
  }
`;

const CategorysWrapper = styled.div`
  position: absolute;
  width: 85%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media only screen and (max-width: 1465px) {
    width: 90%;
  }
  @media only screen and (max-width: 1380px) {
    width: 95%;
  }
  @media only screen and (max-width: 1310px) {
    width: 100%;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    top: 20%;
  }
  @media only screen and (max-width: 900px) and (min-height: 900px) {
    top: 17%;
  }
  @media only screen and (max-width: 900px) and (max-height: 567px) {
    top: 50%;
  }
  @media only screen and (max-width: 490px) {
    top: 17%;
  }
`;
const Main = () => {
  const { categoryContent, activeClass } = useContext(Context);
  return (
    <>
      <MainLeft>
        <TitleWrapper>
          <TitleMap>Design Map</TitleMap>
          <SubTitleMap>Deep Dive in the World of Digital Unicorns</SubTitleMap>
          <a href="https://pcwffdc1g7r.typeform.com/to/MrkIIqsu" target="_blank" rel="noopener noreferrer"><button>Put Yourself on the Map</button></a>
        </TitleWrapper>
      </MainLeft>
      <MainRight activeClass={activeClass}>
        <CategorysWrapper>
          <Vector src={img} />
          {categoryContent.map((category) => (
            <Category
              key={category.id}
              id={category.id}
              popUpListItem={category.items}
              categoryText={category.categoryText}
              className={category.class}
            />
          ))}
        </CategorysWrapper>
      </MainRight>
    </>
  );
};

export default Main;
