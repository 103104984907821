import React, { useContext } from "react";
import { Context } from "../../context/context";
import PopUp from "../popUp/PopUp";
import { ButtonCircle, ButtonCircleInner } from "../styled/Button";
import { CategoryBtnText } from "../styled/ButtonText";


const Category = ({ id, popUpListItem, categoryText, className }) => {
  const { activeClass, handleClick } = useContext(Context);

  return (
    <>
      <PopUp
        popUpListItem={popUpListItem}
        className={activeClass === id ? `active ${className}` : className}
        id={id}
      />
      <CategoryBtnText className={`category ${className}`}>
        {categoryText}
      </CategoryBtnText>
      <ButtonCircle

        onClick={() => handleClick(id)}
        className={`buttons ${className}`}
      >
        <ButtonCircleInner
          className={activeClass === id ? "active" : null}
        ></ButtonCircleInner>
      </ButtonCircle>
    </>
  );
};

export default Category;
