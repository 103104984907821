import React, { createContext, useState } from "react";
import Category from "../static/consts/category";
export const Context = createContext({});

export const Provider = (props) => {
  // active class on Buttons/PopUp
  const [activeClass, setActiveClass] = useState([]);
  const [categoryContent] = useState(Category);

  const handleClick = (id) => {
    if (activeClass === id) {
      setActiveClass([]);
    } else {
      setActiveClass(id);
    }
  };



  let values = {
    handleClick,
    activeClass,
    setActiveClass,
    categoryContent
  };

  return <Context.Provider value={values}>{props.children}</Context.Provider>;
};
